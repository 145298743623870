/* Add your variable customizations of theme here */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body .ui-widget,
body .ui-widget .ui-widget {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}
body .ui-corner-all {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body .ui-corner-right {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body a {
  color: #2D8EE3;
}
body a:hover {
  color: #1D71BC;
}
body a:active {
  color: #15538B;
}
body .ui-helper-reset {
  line-height: normal;
}
body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

body {
  /* Validations */
}
body .ui-inputtext {
  font-size: 13px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #c4c4c4;
  -moz-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #7f7f7f;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-inputtext.ng-dirty {
  border-color: #a80000;
}
body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-chkbox .ui-chkbox-box {
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
  border-color: #7f7f7f;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  border-color: #2D8EE3;
  background-color: #ffffff;
  color: #2D8EE3;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #2D8EE3;
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #15538B;
  background-color: #15538B;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-focus {
  border-color: #1D71BC;
  background-color: #1D71BC;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 16px;
}
body .ui-chkbox-label {
  margin: 0 0 0 0.429em;
}
body .ui-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-radiobutton .ui-radiobutton-box {
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #7f7f7f;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  border-color: #2D8EE3;
  background-color: #ffffff;
  color: #2D8EE3;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #2D8EE3;
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #ffffff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #15538B;
  background-color: #15538B;
  color: #ffffff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-focus {
  border-color: #1D71BC;
  background-color: #1D71BC;
  color: #ffffff;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
  display: none;
}
body .ui-radiobutton-label {
  margin: 0 0 0 0.5em;
}
body .ui-inputswitch {
  height: 1em;
  width: 2.615em !important;
}
body .ui-inputswitch .ui-inputswitch-slider {
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
}
body .ui-inputswitch .ui-inputswitch-slider:before {
  background-color: #e6e6e6;
  height: 1.538em;
  width: 1.538em;
  left: 0;
  bottom: -0.308em;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}
body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #ffffff;
}
body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #ffffff;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #71b2ec;
  border-color: #71b2ec;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  background-color: #449ae6;
}
body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #71b2ec;
  border-color: #71b2ec;
}
body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #71b2ec;
  border-color: #71b2ec;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #7f7f7f;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  padding: 0;
  margin: 0;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 13px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #2D8EE3;
  color: #ffffff;
}
body .ui-autocomplete-panel {
  padding: 0;
  border: 1px solid #dedede;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-autocomplete-panel .ui-autocomplete-items {
  padding: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #d8dae2;
  color: #333333;
}
body .ui-fluid .ui-autocomplete-multiple.ui-autocomplete-dd .ui-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2em);
}
body .ui-fluid .ui-autocomplete-dd .ui-inputtext {
  border-right: 0 none;
  width: calc(100% - 2em);
}
body .ui-chips > ul.ui-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
  border-color: #7f7f7f;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token {
  padding: 0.2145em 0;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  padding: 0;
  margin: 0;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-token {
  font-size: 13px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #2D8EE3;
  color: #ffffff;
}
body .ui-dropdown {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  -moz-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #7f7f7f;
}
body .ui-dropdown:not(.ui-state-disabled):focus {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dropdown .ui-dropdown-label {
  padding-right: 2em;
}
body .ui-dropdown .ui-dropdown-trigger {
  background-color: #ffffff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #333333;
}
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dropdown-panel {
  padding: 0;
  border: 1px solid #dedede;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  right: 1.357em;
  color: #2D8EE3;
}
body .ui-dropdown-panel .ui-dropdown-items {
  padding: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-multiselect {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  -moz-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
}
body .ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #7f7f7f;
}
body .ui-multiselect:not(.ui-state-disabled):focus {
  border-color: #7f7f7f;
  outline: 0 none;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-multiselect .ui-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-multiselect .ui-multiselect-trigger {
  background-color: #ffffff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #333333;
}
body .ui-multiselect-panel {
  padding: 0;
  border: 1px solid #dedede;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-multiselect-panel .ui-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  color: #2D8EE3;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-chkbox {
  margin-right: 0.429em;
  float: none;
  vertical-align: middle;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
  color: #333333;
  top: 50%;
  margin-top: -0.5em;
  right: 0.857em;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:hover {
  color: #2D8EE3;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:focus {
  outline: 0 none;
  color: #2D8EE3;
}
body .ui-multiselect-panel .ui-multiselect-items {
  padding: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.429em 0 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox {
  padding: 0;
  min-width: 12em;
  background: #ffffff;
  border: 1px solid #c4c4c4;
}
body .ui-listbox .ui-chkbox {
  margin: 0 0.429em 0 0;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.429em));
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: #2D8EE3;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.429em 0 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: 1px solid #c4c4c4;
}
body .ui-editor-container .ui-editor-toolbar {
  background-color: #efefef;
}
body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: #333333;
}
body .ui-editor-container .ql-stroke {
  stroke: #333333;
}
body .ui-editor-container .ql-picker-label {
  color: #333333;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover,
body .ui-editor-container .ql-snow .ql-toolbar button:hover,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #323232;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #323232;
}
body .ui-rating a {
  text-align: center;
  display: inline-block;
  color: #333333;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-rating a:hover {
  color: #15538B;
}
body .ui-rating a.ui-rating-cancel {
  color: #e4018d;
}
body .ui-rating a.ui-rating-cancel:hover {
  color: #b5019f;
}
body .ui-rating a .pi-star {
  color: #2D8EE3;
}
body .ui-rating a .ui-rating-icon {
  font-size: 1.429em;
}
body .ui-spinner .ui-spinner-input {
  padding-right: 1.858em;
}
body .ui-spinner .ui-spinner-button {
  width: 1.429em;
}
body .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}
body .ui-fluid .ui-spinner .ui-spinner-input {
  padding-right: 1.858em;
}
body .ui-fluid .ui-spinner .ui-spinner-button {
  width: 1.429em;
}
body .ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  left: 50%;
}
body .ui-slider {
  background-color: #c4c4c4;
  border: 0 none;
}
body .ui-slider.ui-slider-horizontal {
  height: 0.286em;
}
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  margin-top: -0.7145em;
}
body .ui-slider.ui-slider-vertical {
  width: 0.286em;
}
body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: 50%;
  margin-left: -0.7145em;
}
body .ui-slider .ui-slider-handle {
  height: 1.429em;
  width: 1.429em;
  background-color: #ffffff;
  border: 2px solid #7f7f7f;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-slider .ui-slider-range {
  background-color: #2D8EE3;
}
body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  background-color: 2px solid #7f7f7f;
  border: 2px solid #2D8EE3;
}
body .ui-datepicker {
  padding: 0.857em;
  width: 18.571em;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
}
body .ui-datepicker:not(.ui-datepicker-inline) {
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover {
  color: #2D8EE3;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  color: #2D8EE3;
}
body .ui-datepicker:not(.ui-state-disabled) table td:not(.ui-state-disabled) a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: #efefef;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
  background-color: #efefef;
}
body .ui-datepicker .ui-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: #ffffff;
  color: #333333;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #3F4040;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}
body .ui-datepicker table {
  font-size: 13px;
  margin: 0.857em 0 0 0;
}
body .ui-datepicker table th, body .ui-datepicker table td {
  padding: 0.286em;
}
body .ui-datepicker table td > a, body .ui-datepicker table td > span {
  display: block;
  text-align: center;
  width: 1.714em;
  height: 1.714em;
  color: #333333;
  padding: 0.286em;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
body .ui-datepicker table td > a {
  cursor: pointer;
}
body .ui-datepicker table td > a.ui-state-active {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-datepicker table td > a.ui-state-highlight {
  color: #333333;
  background-color: #ffffff;
}
body .ui-datepicker table td.ui-datepicker-today a {
  background-color: #ffffff;
  color: #333333;
  font-weight: 600;
}
body .ui-datepicker table td.ui-datepicker-today a.ui-state-active {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-datepicker .ui-timepicker {
  border: 0 none;
  border-top: 1px solid #d8dae2;
  padding: 0.857em;
}
body .ui-datepicker .ui-timepicker a {
  color: #333333;
  font-size: 1.286em;
}
body .ui-datepicker .ui-timepicker a:hover {
  color: #2D8EE3;
}
body .ui-datepicker .ui-timepicker span {
  font-size: 1.286em;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month {
  color: #333333;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month.ui-state-active {
  color: #ffffff;
  background-color: #2D8EE3;
}
body .ui-datepicker.ui-datepicker-timeonly {
  padding: 0;
}
body .ui-datepicker.ui-datepicker-timeonly .ui-timepicker {
  border-top: 0 none;
}
body .ui-datepicker.ui-datepicker-multiple-month {
  width: auto;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
  border-right: 1px solid #c4c4c4;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:first-child {
  padding-left: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error), body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 2em;
  right: 0;
  top: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-inplace {
  min-height: 2.143em;
}
body .ui-inplace .ui-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-inplace .ui-inplace-display:not(.ui-state-disabled):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-inplace .ui-inplace-content > * {
  vertical-align: middle;
}
body .ui-fileupload .ui-fileupload-buttonbar {
  background-color: #efefef;
  padding: 0.857em 1em;
  border: 1px solid #dedede;
  color: #333333;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
  margin-right: 8px;
}
body .ui-fileupload .ui-fileupload-content {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  color: #333333;
}
body .ui-fileupload .ui-progressbar {
  top: 0;
}
body .ui-fileupload-choose:not(.ui-state-disabled):hover {
  background-color: #1D71BC;
  color: #ffffff;
  border-color: #1D71BC;
}
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #15538B;
  color: #ffffff;
  border-color: #15538B;
}
body .ui-fileupload-choose.ui-state-focus {
  outline: 1px solid #2D8EE3;
  outline-offset: 1px;
}
body .ui-password-panel {
  padding: 0.857em;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-inputgroup .ui-inputgroup-addon {
  border-color: #c4c4c4;
  background-color: #f4f4f4;
  color: #333333;
  padding: 0.429em;
  min-width: 2em;
}
body .ui-inputgroup .ui-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body .ui-inputgroup .ui-inputgroup-addon:last-child {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox .ui-chkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton .ui-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-button:first-child {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body .ui-inputgroup .ui-button:last-child {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body .ui-calendar .ui-datepicker {
  min-width: 19em;
}
body ::-webkit-input-placeholder {
  color: #666666;
}
body :-moz-placeholder {
  color: #666666;
}
body ::-moz-placeholder {
  color: #666666;
}
body :-ms-input-placeholder {
  color: #666666;
}
body .ui-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border-color: #a80000;
}

body .ui-button {
  margin: 0;
  color: #ffffff;
  background-color: #2D8EE3;
  border: 1px solid #2D8EE3;
  font-size: 13px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-button:enabled:hover {
  background-color: #1D71BC;
  color: #ffffff;
  border-color: #1D71BC;
}
body .ui-button:enabled:focus {
  outline: 1px solid #2D8EE3;
  outline-offset: 1px;
  background-color: #449ae6;
}
body .ui-button:enabled:active {
  background-color: #15538B;
  color: #ffffff;
  border-color: #15538B;
}
body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.429em 1em;
}
body .ui-button.ui-button-text-icon-left .ui-button-text {
  padding: 0.429em 1em 0.429em 2em;
}
body .ui-button.ui-button-text-icon-right .ui-button-text {
  padding: 0.429em 2em 0.429em 1em;
}
body .ui-button.ui-button-icon-only {
  width: 2.143em;
}
body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0.429em;
}
body .ui-button.ui-button-raised {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-button.ui-button-rounded {
  -moz-border-radius: 1em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
}
body .ui-fluid .ui-button-icon-only {
  width: 2.143em;
}
body .ui-togglebutton {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  color: #333333;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-togglebutton .ui-button-icon-left {
  color: #666666;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #212121;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #c8c8c8;
  border-color: #7f7f7f;
  color: #333333;
  outline: 0 none;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #212121;
}
body .ui-togglebutton.ui-state-active {
  background-color: #2D8EE3;
  border-color: #2D8EE3;
  color: #ffffff;
}
body .ui-togglebutton.ui-state-active .ui-button-icon-left {
  color: #ffffff;
}
body .ui-togglebutton.ui-state-active:hover {
  background-color: #15538B;
  border-color: #15538B;
  color: #ffffff;
}
body .ui-togglebutton.ui-state-active:hover .ui-button-icon-left {
  color: #ffffff;
}
body .ui-togglebutton.ui-state-active.ui-state-focus {
  background-color: #2D8EE3;
  border-color: #7f7f7f;
  color: #ffffff;
}
body .ui-togglebutton.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #ffffff;
}
body .ui-selectbutton .ui-button {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  color: #333333;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-selectbutton .ui-button .ui-button-icon-left {
  color: #666666;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #212121;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #c8c8c8;
  border-color: #7f7f7f;
  color: #333333;
  outline: 0 none;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #212121;
}
body .ui-selectbutton .ui-button.ui-state-active {
  background-color: #2D8EE3;
  border-color: #2D8EE3;
  color: #ffffff;
}
body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left {
  color: #ffffff;
}
body .ui-selectbutton .ui-button.ui-state-active:hover {
  background-color: #15538B;
  border-color: #15538B;
  color: #ffffff;
}
body .ui-selectbutton .ui-button.ui-state-active:hover .ui-button-icon-left {
  color: #ffffff;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus {
  background-color: #2D8EE3;
  border-color: #7f7f7f;
  color: #ffffff;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #ffffff;
}
body .ui-selectbutton .ui-button:first-child {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body .ui-selectbutton .ui-button:last-child {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media (max-width: 640px) {
  body .ui-buttonset .ui-button {
    margin-bottom: 1px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
}
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-menu {
  width: 100%;
  min-width: 12.5em;
}
body .ui-button.ui-state-default.ui-button-secondary, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default {
  color: #333333;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:hover, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:hover {
  background-color: #d1d1d1;
  color: #212121;
  border-color: #d1d1d1;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  background-color: #dedede;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:active, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:active {
  background-color: #2D8EE3;
  color: #ffffff;
  border-color: #2D8EE3;
}
body .ui-button.ui-state-default.ui-button-info, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
}
body .ui-button.ui-state-default.ui-button-info:enabled:hover, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:hover {
  background-color: #116fbf;
  color: #ffffff;
  border-color: #116fbf;
}
body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  background-color: #0088f3;
  outline-color: #0088f3;
}
body .ui-button.ui-state-default.ui-button-info:enabled:active, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:active {
  background-color: #005b9f;
  color: #ffffff;
  border-color: #005b9f;
  outline-color: #005b9f;
}
body .ui-button.ui-state-default.ui-button-success, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #34A835;
  border: 1px solid #34A835;
}
body .ui-button.ui-state-default.ui-button-success:enabled:hover, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:hover {
  background-color: #107D11;
  color: #ffffff;
  border-color: #107D11;
}
body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  background-color: #3abb3b;
  outline-color: #3abb3b;
}
body .ui-button.ui-state-default.ui-button-success:enabled:active, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:active {
  background-color: #0C6B0D;
  color: #ffffff;
  border-color: #0C6B0D;
  outline-color: #0C6B0D;
}
body .ui-button.ui-state-default.ui-button-warning, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default {
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:hover, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:hover {
  background-color: #ED990B;
  color: #333333;
  border-color: #ED990B;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  background-color: #ffc11b;
  outline-color: #ffc11b;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:active, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:active {
  background-color: #D38B10;
  color: #333333;
  border-color: #D38B10;
  outline-color: #D38B10;
}
body .ui-button.ui-state-default.ui-button-danger, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #e91224;
  border: 1px solid #e91224;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:hover, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:hover {
  background-color: #c01120;
  color: #ffffff;
  border-color: #c01120;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  background-color: #ee2637;
  outline-color: #ee2637;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:active, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:active {
  background-color: #a90000;
  color: #ffffff;
  border-color: #a90000;
  outline-color: #a90000;
}

body .ui-widget-content p {
  line-height: 1.5;
  margin: 0;
}
body .ui-panel {
  padding: 0;
  border: 0 none;
}
body .ui-panel .ui-panel-titlebar {
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  background-color: #efefef;
  color: #333333;
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-panel .ui-panel-titlebar .ui-panel-title {
  vertical-align: middle;
  font-weight: 700;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: 13px;
  color: #6f6f6f;
  border: 1px solid transparent;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #323232;
}
body .ui-panel .ui-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-panel .ui-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  border-top: 0 none;
  margin: 0;
}
body .ui-fieldset {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
}
body .ui-fieldset .ui-fieldset-legend a {
  padding: 0.857em 1em;
  border: 1px solid #dedede;
  color: #333333;
  background-color: #efefef;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #6f6f6f;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
  padding: 0;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  color: #333333;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover .ui-fieldset-toggler {
  color: #333333;
}
body .ui-fieldset .ui-fieldset-content {
  padding: 0;
}
body .ui-accordion .ui-accordion-header {
  margin-bottom: 2px;
}
body .ui-accordion .ui-accordion-header a {
  padding: 0.857em 1em;
  border: 1px solid #dedede;
  color: #333333;
  background-color: #efefef;
  color: #333333;
}
body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  color: #6f6f6f;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #2D8EE3;
  border: 1px solid #2D8EE3;
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
  border: 1px solid #1D71BC;
  background-color: #1D71BC;
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
  color: #ffffff;
}
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  background: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 1px solid #dedede;
  background-color: #efefef;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #333333;
  padding: 0.857em 1em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
  color: #6f6f6f;
  margin-right: 0.429em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
  color: #6f6f6f;
  margin-left: 0.429em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
  color: #6f6f6f;
  margin: 0 0.429em 0 -0.429em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-tabview-right-icon {
  color: #323232;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #2D8EE3;
  border: 1px solid #2D8EE3;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border: 1px solid #1D71BC;
  background-color: #1D71BC;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 0.214em;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  margin-right: 0.214em;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  margin-bottom: 0.214em;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  margin-bottom: 0.214em;
}
body .ui-tabview .ui-tabview-panels {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  color: #333333;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  padding: 0;
}
body .ui-toolbar {
  background-color: #efefef;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
}
body .ui-toolbar button {
  vertical-align: middle;
}

body .ui-paginator {
  background-color: #ffffff;
  border: 1px solid #dedede;
  padding: 0;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #737373;
  height: 2.308em;
  min-width: 2.308em;
  border: 0 none;
  line-height: 2.308em;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-paginator .ui-paginator-first:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-prev:before {
  position: relative;
}
body .ui-paginator .ui-paginator-next:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-last:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #737373;
  height: 2.308em;
  min-width: 2.308em;
  border: 0 none;
  line-height: 2.308em;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-paginator .ui-dropdown {
  margin-left: 0.429em;
  height: 2.308em;
  min-width: auto;
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  text-align: center;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
}
body .ui-table .ui-table-summary {
  border-top: 0 none;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.5em 1.214em;
  border: 1px solid #dedede;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff;
}
body .ui-table .ui-table-tbody > tr > td {
  padding: 0.5em 1.214em;
}
body .ui-table .ui-table-tfoot > tr > td {
  padding: 0.5em 1.214em;
  border: 1px solid #dedede;
  font-weight: 500;
  color: #333333;
  background-color: #ffffff;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #333333;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr {
  background: #ffffff;
  color: #333333;
}
body .ui-table .ui-table-tbody > tr > td {
  background: inherit;
  border: 1px solid #dedede;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-table .ui-column-resizer-helper {
  background-color: #2D8EE3;
}
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #dedede;
  }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #dedede;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none;
  }
}
body .ui-datagrid .ui-datagrid-header {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datagrid .ui-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datagrid .ui-datagrid-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datalist .ui-datalist-header {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datalist .ui-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datalist .ui-datalist-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datascroller .ui-datascroller-header {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datascroller .ui-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datascroller .ui-datascroller-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-dataview .ui-dataview-header {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-dataview .ui-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
}
body .ui-dataview .ui-dataview-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .fc .fc-button {
  color: #ffffff;
  background-color: #2D8EE3;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  height: 2.25em;
  padding: 0 1em;
  border: 1px solid #2D8EE3;
}
body .fc .fc-button:enabled:not(:focus):not(.fc-button-active):hover {
  background-color: #1D71BC;
  color: #ffffff;
  border-color: #1D71BC;
}
body .fc .fc-button:enabled:not(.fc-button-active):focus {
  outline: 1px solid #2D8EE3;
  outline-offset: 1px;
  background-color: #449ae6;
  box-shadow: none;
}
body .fc .fc-button.fc-button-active {
  background-color: #15538B;
  color: #ffffff;
  border-color: #15538B;
}
body .fc .fc-button.fc-button-active:focus {
  box-shadow: none;
}
body .fc .fc-head .fc-row {
  background-color: #efefef;
  color: #333333;
}
body .fc .fc-head .fc-row th {
  border: 1px solid #dedede;
  padding: 0.857em 1em;
}
body .fc td.fc-today {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .fc .fc-toolbar .ui-state-active {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .fc .fc-event {
  background-color: #1d81da;
  color: #ffffff;
}
body .fc table {
  box-sizing: border-box;
}
body .fc div.ui-widget-content {
  padding-left: 0px;
  padding-right: 0px;
}
body .ui-fluid .fc .fc-toolbar .ui-button {
  width: auto;
}
body .ui-picklist .ui-picklist-buttons button {
  font-size: 15px;
}
body .ui-picklist .ui-picklist-caption {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
}
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #dedede;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none;
  border-top: 0 none;
}
body .ui-picklist .ui-picklist-filter-container input {
  width: 100%;
  padding-right: 2em;
}
body .ui-picklist .ui-picklist-filter-container .pi {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2D8EE3;
}
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em;
}
body .ui-picklist .ui-picklist-list {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
@media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em;
  }
}
body .ui-orderlist .ui-orderlist-controls {
  padding: 0.571em 1em;
}
body .ui-orderlist .ui-orderlist-controls button {
  font-size: 15px;
}
body .ui-orderlist .ui-orderlist-caption {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
}
body .ui-orderlist .ui-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #dedede;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none;
  border-top: 0 none;
}
body .ui-orderlist .ui-orderlist-filter-container input {
  width: 100%;
  padding-right: 2em;
}
body .ui-orderlist .ui-orderlist-filter-container .pi {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2D8EE3;
}
body .ui-orderlist .ui-orderlist-list {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-tree {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree .ui-tree-container {
  padding: 0;
  margin: 0;
}
body .ui-tree .ui-tree-container .ui-treenode {
  padding: 0.143em 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  padding: 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #737373;
  font-size: 15px;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #737373;
  font-size: 15px;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.429em 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
  margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
  line-height: inherit;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
  background-color: #2D8EE3;
}
body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
  color: #2D8EE3;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
  color: #114c80;
}
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #dedede;
  border-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #dedede;
  border-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-node-content {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #737373;
}
body .ui-carousel .ui-carousel-content .ui-carousel-prev,
body .ui-carousel .ui-carousel-content .ui-carousel-next {
  background-color: #2D8EE3;
  border: 1px solid #2D8EE3;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin: 0.2em;
  color: #ffffff;
  width: auto;
  min-width: 2.5em;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-carousel .ui-carousel-content .ui-carousel-prev:not(.ui-state-disabled):hover,
body .ui-carousel .ui-carousel-content .ui-carousel-next:not(.ui-state-disabled):hover {
  background-color: #1D71BC;
  color: #ffffff;
  border-color: #1D71BC;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item > .ui-button {
  border-color: transparent;
  background-color: transparent;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon {
  width: 20px;
  height: 6px;
  background-color: #b2c1cd;
  margin: 0 0.2em;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon::before {
  content: " ";
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item.ui-state-highlight .ui-carousel-dot-icon {
  background-color: #2D8EE3;
}
body .ui-treetable .ui-treetable-caption,
body .ui-treetable .ui-treetable-summary {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  text-align: center;
}
body .ui-treetable .ui-treetable-caption {
  border-bottom: 0 none;
}
body .ui-treetable .ui-treetable-summary {
  border-top: 0 none;
}
body .ui-treetable .ui-treetable-thead > tr > th {
  padding: 0.5em 1.214em;
  border: 1px solid #dedede;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  padding: 0.5em 1.214em;
}
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: 0.5em 1.214em;
  border: 1px solid #dedede;
  font-weight: 500;
  color: #333333;
  background-color: #ffffff;
}
body .ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #333333;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #ffffff;
}
body .ui-treetable .ui-treetable-tbody > tr {
  background: #ffffff;
  color: #333333;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  background: inherit;
  border: 1px solid #dedede;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-toggler {
  color: #737373;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 15px;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.429em;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td {
  background: inherit;
  border: 1px solid #dedede;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td .ui-treetable-toggler {
  color: #ffffff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td {
  background: inherit;
  border: 1px solid #dedede;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td .ui-treetable-toggler {
  color: #ffffff;
}
body .ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-treetable .ui-column-resizer-helper {
  background-color: #2D8EE3;
}
body .ui-virtualscroller .ui-virtualscroller-header {
  background-color: #efefef;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.857em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-content {
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #333333;
}
body .ui-virtualscroller .ui-virtualscroller-content .ui-virtualscroller-list li {
  border-bottom: 1px solid #dedede;
}
body .ui-virtualscroller .ui-virtualscroller-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .ui-messages {
  padding: 1em;
  margin: 1em 0;
}
body .ui-messages.ui-messages-info {
  background-color: #F9FCFF;
  border: 1px solid #82C2FF;
  color: #071A38;
}
body .ui-messages.ui-messages-info .ui-messages-icon {
  color: #071A38;
}
body .ui-messages.ui-messages-info .ui-messages-close {
  color: #071A38;
}
body .ui-messages.ui-messages-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-messages.ui-messages-success .ui-messages-icon {
  color: #212121;
}
body .ui-messages.ui-messages-success .ui-messages-close {
  color: #212121;
}
body .ui-messages.ui-messages-warn {
  background-color: #FFF0D2;
  border: 1px solid #FFD66B;
  color: #4D3B0A;
}
body .ui-messages.ui-messages-warn .ui-messages-icon {
  color: #4D3B0A;
}
body .ui-messages.ui-messages-warn .ui-messages-close {
  color: #4D3B0A;
}
body .ui-messages.ui-messages-error {
  background-color: #FF8474;
  border: 1px solid #FF3318;
  color: #370909;
}
body .ui-messages.ui-messages-error .ui-messages-icon {
  color: #370909;
}
body .ui-messages.ui-messages-error .ui-messages-close {
  color: #370909;
}
body .ui-messages .ui-messages-close {
  font-size: 1.5em;
  top: 0.25em;
  right: 0.5em;
}
body .ui-messages .ui-messages-icon {
  font-size: 1.714em;
}
body .ui-message {
  padding: 0.35em;
}
body .ui-message.ui-message-info {
  background-color: #F9FCFF;
  border: 1px solid #82C2FF;
  color: #071A38;
}
body .ui-message.ui-message-warn {
  background-color: #FFF0D2;
  border: 1px solid #FFD66B;
  color: #4D3B0A;
}
body .ui-message.ui-message-error {
  background-color: #FF8474;
  border: 1px solid #FF3318;
  color: #370909;
}
body .ui-message.ui-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-message .ui-message-close {
  text-decoration: none;
  color: #fff;
  right: 0.25em;
}
body .ui-growl {
  top: 120px;
}
body .ui-growl .ui-growl-item-container {
  margin: 0 0 1em 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-growl .ui-growl-item-container .ui-growl-item {
  padding: 1em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-message {
  margin: 0 0 0 4em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-image {
  font-size: 3.429em;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info {
  background-color: #F9FCFF;
  border: 1px solid #82C2FF;
  color: #071A38;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info .ui-growl-image {
  color: #071A38;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success .ui-growl-image {
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn {
  background-color: #FFF0D2;
  border: 1px solid #FFD66B;
  color: #4D3B0A;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn .ui-growl-image {
  color: #4D3B0A;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error {
  background-color: #FF8474;
  border: 1px solid #FF3318;
  color: #370909;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error .ui-growl-image {
  color: #370909;
}
body .ui-toast .ui-toast-message {
  -webkit-box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-toast .ui-toast-message.ui-toast-message-info {
  background-color: #F9FCFF;
  border: 1px solid #82C2FF;
  color: #071A38;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
  color: #071A38;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-icon {
  color: #60b0ff;
}
body .ui-toast .ui-toast-message.ui-toast-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-icon {
  color: #56a056;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn {
  background-color: #FFF0D2;
  border: 1px solid #FFD66B;
  color: #4D3B0A;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-close-icon {
  color: #4D3B0A;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-icon {
  color: #ffbd39;
}
body .ui-toast .ui-toast-message.ui-toast-message-error {
  background-color: #FF8474;
  border: 1px solid #FF3318;
  color: #370909;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
  color: #370909;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-icon {
  color: #da1900;
}

body .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
body .ui-overlaypanel {
  background-color: #ffffff;
  color: #333333;
  padding: 0;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-overlaypanel .ui-overlaypanel-content {
  padding: 0.571em 1em;
}
body .ui-overlaypanel .ui-overlaypanel-close {
  background-color: #2D8EE3;
  color: #ffffff;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
}
body .ui-overlaypanel .ui-overlaypanel-close:hover {
  background-color: #15538B;
  color: #ffffff;
}
body .ui-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
body .ui-overlaypanel:before {
  border-color: rgba(222, 222, 222, 0);
  border-bottom-color: #dedede;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:before {
  border-top-color: #dedede;
}
body .ui-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #dedede;
  background-color: #efefef;
  color: #333333;
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  margin: 0;
  float: none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #6f6f6f;
  border: 0 none;
  padding: 0;
  margin-left: 0.429em;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #323232;
}
body .ui-dialog .ui-dialog-content {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
}
body .ui-dialog .ui-dialog-footer {
  border: 1px solid #dedede;
  border-top: 0 none;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
}
body .ui-dialog .ui-dialog-footer button {
  margin: 0 0.429em 0 0;
  width: auto;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
  padding: 1.5em;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 13px;
  margin: 0;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span.ui-icon {
  margin-right: 0.35em;
  font-size: 15px;
}
body .ui-sidebar {
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-tooltip .ui-tooltip-text {
  background-color: #333333;
  color: #ffffff;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #333333;
}
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #333333;
}
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #333333;
}
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #333333;
}
body .ui-lightbox {
  -webkit-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.429em 0 rgba(0, 0, 0, 0.16);
}
body .ui-lightbox .ui-lightbox-caption {
  border: 1px solid #dedede;
  background-color: #efefef;
  color: #333333;
  padding: 0.857em 1em;
  font-weight: 700;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  color: #333333;
  margin: 0;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  padding: 0;
  color: #6f6f6f;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  color: #323232;
}
body .ui-lightbox .ui-lightbox-content-wrapper {
  overflow: hidden;
  background-color: #ffffff;
  color: #333333;
  border: 0 none;
  padding: 0;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 3em;
  color: #ffffff;
  margin-top: -0.5em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading ~ a {
  display: none;
}

body .ui-breadcrumb {
  background-color: #ffffff;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
}
body .ui-breadcrumb ul li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #333333;
  margin: 0;
  float: none;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  margin: 0 0.429em 0 0.429em;
  color: #737373;
}
body .ui-breadcrumb ul li:first-child a {
  color: #737373;
  margin: 0;
  font-size: 17px;
}
body .ui-steps {
  position: relative;
}
body .ui-steps .ui-steps-item {
  background-color: transparent;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #dedede;
  position: relative;
  top: 13px;
  margin-bottom: 13px;
  width: 2em;
  height: 2em;
  font-size: 15px;
  line-height: -2em;
  text-align: center;
  padding-top: 0.25em;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: block;
  margin-top: 6px;
  color: #737373;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background: #2D8EE3;
  color: #ffffff;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #333333;
}
body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  display: block;
}
body .ui-steps:before {
  content: " ";
  border-top: 1px solid #dedede;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}
body .ui-menu .ui-menuitem-link,
body .ui-menubar .ui-menuitem-link,
body .ui-tieredmenu .ui-menuitem-link,
body .ui-contextmenu .ui-menuitem-link,
body .ui-megamenu .ui-menuitem-link,
body .ui-slidemenu .ui-menuitem-link {
  padding: 0.571em 0.857em;
  color: #333333;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-menu .ui-menuitem-link:hover,
body .ui-menubar .ui-menuitem-link:hover,
body .ui-tieredmenu .ui-menuitem-link:hover,
body .ui-contextmenu .ui-menuitem-link:hover,
body .ui-megamenu .ui-menuitem-link:hover,
body .ui-slidemenu .ui-menuitem-link:hover {
  background-color: #eaeaea;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #333333;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #333333;
}
body .ui-menu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
body .ui-menu .ui-menuitem {
  margin: 0;
}
body .ui-menu.ui-shadow {
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-menu .ui-submenu-header {
  margin: 0;
  padding: 0.857em 1em;
  color: #333333;
  background-color: #efefef;
  font-weight: 700;
  border: 0 none;
}
body .ui-menubar {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
body .ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
  padding: 0.571em 0.857em;
}
body .ui-menubar .ui-submenu-list {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-menubar .ui-menuitem {
  margin: 0;
}
body .ui-menubar .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  vertical-align: middle;
  right: 0.429em;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-contextmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-contextmenu .ui-submenu-list {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-contextmenu .ui-menuitem {
  margin: 0;
}
body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-tieredmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
body .ui-tieredmenu .ui-submenu-list {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-tieredmenu .ui-menuitem {
  margin: 0;
}
body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-slidemenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
body .ui-slidemenu .ui-submenu-list {
  padding: 0;
  background-color: #ffffff;
  border: 0 none;
}
body .ui-slidemenu .ui-menuitem {
  margin: 0;
}
body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-slidemenu .ui-slidemenu-backward {
  margin: 0;
  padding: 0.857em 1em;
  color: #333333;
  background-color: #efefef;
  font-weight: 700;
  border: 0 none;
}
body .ui-slidemenu .ui-slidemenu-backward:hover {
  background-color: #d1d1d1;
  color: #333333;
}
body .ui-tabmenu {
  border: 0 none;
}
body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
  background: #ffffff;
  border-bottom: 1px solid #dedede;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  position: static;
  border: 1px solid #dedede;
  background-color: #efefef;
  margin-right: 0.214em;
  margin-bottom: -1px;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: #333333;
  padding: 0.857em 1em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
  margin-right: 0.429em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-icon {
  color: #6f6f6f;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-icon {
  color: #323232;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
  background-color: #2D8EE3;
  border: 1px solid #2D8EE3;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-text {
  color: #ffffff;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-icon {
  color: #ffffff;
}
body .ui-megamenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
body .ui-megamenu .ui-megamenu-submenu-header {
  margin: 0;
  padding: 0.857em 1em;
  color: #333333;
  background-color: #efefef;
  font-weight: 700;
  border: 0 none;
}
body .ui-megamenu .ui-megamenu-panel {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
body .ui-megamenu .ui-menuitem {
  margin: 0;
}
body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-megamenu.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
  vertical-align: middle;
  right: 0.429em;
}
body .ui-panelmenu .ui-icon {
  position: static;
}
body .ui-panelmenu .ui-panelmenu-header {
  padding: 0;
}
body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid #dedede;
  background-color: #efefef;
  color: #333333;
  padding: 0.857em 1em;
  font-weight: 700;
  position: static;
  font-size: 13px;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #6f6f6f;
  position: static;
  display: inline-block;
  margin-right: 0.429em;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  outline: 0 none;
  border: 1px solid #d1d1d1;
  background-color: #d1d1d1;
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #2D8EE3;
  background-color: #2D8EE3;
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
  outline: 0 none;
  border: 1px solid #1D71BC;
  background-color: #1D71BC;
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 2px;
}
body .ui-panelmenu .ui-panelmenu-panel:first-child {
  margin-top: 0;
}
body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #dedede;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  margin: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  padding: 0.571em 0.857em;
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
  background-color: #eaeaea;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-icon, body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-panelmenu-icon {
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-text {
  color: #333333;
}

body .ui-progressbar {
  border: 0 none;
  height: 1.714em;
  background-color: #efefef;
}
body .ui-progressbar .ui-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #2D8EE3;
}
body .ui-progressbar .ui-progressbar-label {
  margin-top: 0;
  color: #333333;
  display: none !important;
}
body .ui-galleria .ui-galleria-nav-prev,
body .ui-galleria .ui-galleria-nav-next {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  color: #737373;
}
body .ui-galleria .ui-galleria-nav-prev:hover,
body .ui-galleria .ui-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 15px;
}
body .ui-galleria .ui-galleria-nav-next {
  font-size: 15px;
}
body .ui-terminal {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dedede;
  padding: 0.571em 1em;
}
body .ui-terminal .ui-terminal-input {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  height: 15px;
}
body .ui-terminal .ui-terminal-command {
  height: 15px;
}

/* Add your customizations of the theme here */

body .p-tabview .p-tabview-nav li .p-tabview-nav-link{
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
}
body .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  border: 1px solid #1D71BC;
  background-color: #1D71BC;
  color: white;
}
body .p-tabview .p-tabview-nav li {
  margin-right: 0.214em;
}